import React from "react";
import { CheckCircleIcon } from "@heroicons/react/solid";

export default function Success({
  msg,

  children,
}) {
  return (
    <div className="p-4 mt-5 rounded-md bg-green-50">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="w-5 h-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h2 className="text-green-800">{msg}</h2>
          <div className="mt-2 text-sm text-green-700">
            <p>{children}</p>
          </div>
          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
