import React, { useEffect } from "react";

import Success from "../../components/notifications/SuccessMessage";
import Error from "../../components/notifications/Error";
import Layout4 from "../../components/layout/layout4";
import SEO from "../../components/seo";
import { triggerGoogleTagEvent } from "../../components/shared/GoogleTagEvent2";

const SuccessPage = () => {
  useEffect(() => {
    triggerGoogleTagEvent();
    console.log("Navigation complete!");
  }, []);

  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>

      <Layout4>
        <SEO
          title="MSPI Application Form"
          description="Miss Singapore International will represent Singapore on an International platform with 84 contestants around the world. The exchanges and exposure aim to bring forth more women to anchor a role in the society with a charitable approach, grace, integrity and honour."
          image="https://res.cloudinary.com/dkm14ge3g/image/upload/v1638588570/Logo/qiuk4zzjcgmidemdqavw.webp"
          imageAlt="Miss Singapore International Pageant | Annual Charity Gala Dinner"
        />

        <div className="relative">
          <div className="absolute inset-0 ">
            <img
              className="object-cover object-top w-full h-full grayscale"
              src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637152184/formPages/rgj2asybbv8wwcugcvhj.jpg"
              alt=""
            />

            <div
              className="absolute inset-0 bg-opacity-50 bg-swa-2 mix-blend-multiply"
              aria-hidden="true"
            />
          </div>

          <div className="relative px-4 mx-auto py-14 max-w-7xl sm:py-24 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
              Miss Singapore Pageant International application form
            </h1>

            <p className="max-w-3xl mt-6 text-xl text-indigo-100">
              Representing Singapore in Miss International Pageant. <br />
              {/* <span className="text-2xl font-bold text-swa-4"> 

Beauty with Heart and Purpose 

</span> */}
            </p>
          </div>
        </div>

        <div className="mx-3 my-8 rounded-lg md:mx-auto md:max-w-xl lg:max-w-2xl">
          <Success msg="Submitted">
            You will be notified if you are shortlisted.
          </Success>
        </div>
      </Layout4>
    </>
  );
};

export default SuccessPage;
