import React, { useState } from "react";
import Footer from "../layout/footer";

// import Navbar2 from "./navbar2"
import Navbar from "./navbar";

const Layout2 = ({ children }) => {
  const [showWhat, setShowWhat] = useState(false);
  const [showHow, setShowHow] = useState(false);
  const [showPart, setShowPart] = useState(false);

  const handleSubMenu = id => {
    if (id === "What") {
      setShowHow(false);
      setShowPart(false);
      setShowWhat(!showWhat);
    } else if (id === "How") {
      setShowWhat(false);
      setShowPart(false);
      setShowHow(!showHow);
    } else if (id === "Participate") {
      setShowWhat(false);
      setShowHow(false);
      setShowPart(!showPart);
    }
  };

  const handleSubMenuClose = () => {
    setShowWhat(false);
    setShowHow(false);
    setShowPart(false);
  };

  return (
    <div className="">
      <Navbar
        showWhat={showWhat}
        showHow={showHow}
        showPart={showPart}
        handleSubMenu={handleSubMenu}
        role="handlemenu"
      />
      <div
        onClick={handleSubMenuClose}
        role="none"
        tabIndex={0}
        onKeyDown={handleSubMenuClose}
        className=""
      >
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout2;
